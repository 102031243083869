import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import SiteMetadata from "../components/SiteMetadata"
import Carousel from "../components/Carousel"
import Layout from "../layouts/Layout"

export default props => {
  const {
    description,
    gallery,
    name,
    summary,
    thumbnail,
    urlGooglePlay,
    urlAppStore,
    urlAmazonStore,
  } = props.data.item

  return (
    <Layout>
      <SiteMetadata
        title={name}
        description={summary}
        image={thumbnail.localFile.publicURL}
      />
      <div className="bg-gray-0 py-12 lg:py-16">
        <div className="container">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-2/3 pb-8">
              {gallery && gallery.length === 1 && (
                <Img
                  fluid={gallery[0].localFile.childImageSharp.fluid}
                  alt={name}
                />
              )}
              {gallery && gallery.length > 1 && <Carousel images={gallery} />}
            </div>
            <div className="w-full lg:w-1/3 lg:pl-8 xl:pl-12">
              <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-1">
                {name}
              </h1>
              <h2 className="text-xl leading-tight font-semibold tracking-tight text-blue-600 sm:text-2xl">
                {summary}
              </h2>
              {description && (
                <div className="my-4 text-base text-gray-700 whitespace-pre-line">
                  {description.description}
                </div>
              )}
              {urlGooglePlay && (
                <div className="mt-6 w-48">
                  <a href={urlGooglePlay} target="_blank"><img  src="https://d11k65zkm4nqwc.cloudfront.net/shared/images/google-play-badge-12-2016.png" alt="GET IT ON Google Play"/></a>
                </div>
              )}
              {urlAppStore && (
                <div className="mt-6 w-48">
                  <a href={urlAppStore} target="_blank"><img  src="https://d11k65zkm4nqwc.cloudfront.net/shared/images/appstore-badge-2018-03-100.png" alt="Download on the App Store"/></a>
                </div>
              )}
              {urlAmazonStore && (
                <div className="mt-6 w-48">
                  <a href={urlAmazonStore} target="_blank"><img  src="https://d11k65zkm4nqwc.cloudfront.net/shared/images/amazon-badge-2018-03-100.png" alt="Download on Amazon Appstore"/></a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query GameItemQUery($slug: String!) {
    item: contentfulGame(slug: { eq: $slug }) {
      description {
        description
      }
      gallery {
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 960, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
      }
      name
      summary
      thumbnail {
        localFile {
          publicURL
        }
      }
      urlGooglePlay
      urlAppStore
      urlAmazonStore
    }
  }
`
